import { Component, OnInit,Inject, OnDestroy, ViewChild, ElementRef,ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonModule }  from '@angular/common';
import { first } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { AppConfigService } from "../../services/app-config.service"; 

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SocialAuthService } from "@abacritt/angularx-social-login";
import { SocialUser } from "@abacritt/angularx-social-login";
import { MicrosoftLoginProvider,GoogleLoginProvider } from "@abacritt/angularx-social-login";
import {AdminUser} from '../../models/user';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: {'class':'d-block h-100'},
  encapsulation:ViewEncapsulation.None
  
})
export class LoginComponent implements OnInit, OnDestroy {
  private readonly _destroying$ = new Subject<void>();
  @ViewChild('googleBtnRef')
  googleBtn?: ElementRef;

  public user: SocialUser = new SocialUser;
  loginForm: UntypedFormGroup=new UntypedFormGroup({username:new UntypedFormControl(""),password:new UntypedFormControl("") });
  currentyear: number = new Date().getFullYear();
loading = false;
submitted = false;
validationsubmited=false;
isValidLogin=false;
returnUrl: string="";
brandTitle:string=AppConfigService.settings.brandname;
brandSubTitle:string=AppConfigService.settings.brandsubtitle;
error = '';
  constructor(private authS: AuthService,private formBuilder: UntypedFormBuilder,private route: ActivatedRoute,
    private router: Router,private authService: SocialAuthService) {
var surrentUser=this.authS.AdminUserValue;
if(surrentUser){
  this.router.navigate(['/']); 
}

     }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      password: ['', Validators.required]
  });
   console.log(this.authService);
   //this.authService.signOut(true);
    this.authService.authState.subscribe(async user => {

      if(user!=null){
        this.user = user;
        this.submitted = false;
    this.validationsubmited=true;
       if(user.provider=='MICROSOFT'){
      
         (await this.authS.login(user.email, null, 0, 1)).subscribe(async resp=>{
          this.submitted = true;
          this.validationsubmited=false;
          if(resp["Message"]!='Fail'){
         this.authS.AdminUserValue={name:user.firstName,token:user.authToken,email:user.email,id:user.id};
            await localStorage.setItem('buddyadmin', JSON.stringify({name:user.firstName,token:user.authToken,email:user.email,id:user.id}));
            this.isValidLogin=true;
            this.submitted = false;
            this.router.navigate(['/']); 
          }else{
            this.isValidLogin=false;
          }
       });
       }else{
      
          (await this.authS.login(user.email, null, 1, 0)).subscribe(async resp=>{
            this.submitted = true;
            this.validationsubmited=false;
            if(resp["Message"]!='Fail'){
              this.authS.AdminUserValue={name:user.firstName,token:user.authToken,email:user.email,id:user.id};
              await localStorage.setItem('buddyadmin', JSON.stringify({name:user.firstName,token:user.authToken,email:user.email,id:user.id}));
              this.isValidLogin=true;
              this.submitted = false;
              this.router.navigate(['/']); 
          }else{
            this.isValidLogin=false;
          }
        });
       }
      }
     
    });
  
   
  }
  async handleCredentialResponse(response: any) {
  
  }
  get f() { return this.loginForm.controls; }
  async onSubmit(event:Event)  {
    event.preventDefault();
    let self=this;
    this.submitted = true;


    if (this.loginForm.invalid) return;
    this.loading = true;
    this.validationsubmited=true;
    var isLoged=(await this.authS.login(this.f["username"].value, this.f["password"].value,0,0)).subscribe(resp=>{

    });
    this.loading = false;
    /* if(isLoged){
     
      this.isValidLogin=true;
      this.router.navigate(['/']); 
    }else{
      this.isValidLogin=false;
    }

    setTimeout(()=>{
      this.loading = false;
      this.validationsubmited=false;
    },600);
    */


 

  }
  loginPopup() {
    this.authService.signIn(MicrosoftLoginProvider.PROVIDER_ID,{});
    
  }
 

  signInwithGoogle(){
      this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

}
