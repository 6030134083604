import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {AdminUser} from '../models/user';
import { AppConfigService } from "./app-config.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public UserSubject: BehaviorSubject<any>;
  public User: Observable<any>;
  apiurl=AppConfigService.settings.apiServer.apiurl;
  constructor(private http: HttpClient) {  
    var isbuddyAdmin=localStorage.getItem('buddyadmin');
    this.UserSubject = new BehaviorSubject<AdminUser>(JSON.parse(isbuddyAdmin!));
    
    this.User = this.UserSubject.asObservable();
  }
  public get AdminUserValue():any {
    return this.UserSubject.value;
}
 set AdminUserValue(userData:object){

   this.UserSubject.next(userData);
  
}
async login(username: string, password: any,isgoogle:number,ismicrosoft:number) {
 var loginurl=this.apiurl+'birdie/logincheck'; 
  
   return this.http.post<any>(loginurl,{username:username,password:password,isgoogle:isgoogle,ismicrosoft:ismicrosoft});
 
  /*if(username=='pmooganur@alicorn.co' && password=='12345'){
var userData:AdminUser={
  Id: 1,
  Email: "pmooganur@alicorn.co",
 Name: 'Admin',
 token: '123456asdfgh'
};

this.UserSubject.next(userData);
await localStorage.setItem('buddyadmin', JSON.stringify(userData));
return userData;
  }else{
    return false;
  }*/
  
}

logout() {
  localStorage.removeItem('buddyadmin');
  this.UserSubject.next(null);
}
}
