<div class="h-100 d-flex ">
    <div class="row  w-100 m-0">
	<div class="col-lg-2 col-md-1  bg-dark " style="--bs-dark-rgb:149,117,205;"></div>
        <div class="col-lg-7 col-md-7 bg-dark bg-opacity-75 d-flex align-items-center justify-content-center" style="--bs-dark-rgb:149,117,205;">
            <div class="text-center mb-4">
                <div class="mb-3">
                <img src="/assets/img/birdie_logo.png" style="max-width: 100%;" width="53" height="94" class="img-fluid" alt="image"></div><h1 class="display-3 fw-bold text-white">{{brandTitle}}</h1>
<h4 class="text-white"><i>{{brandSubTitle}}</i></h4>			</div> 
        </div>
<div class="col-lg-3 col-md-4  bg-opacity-25 bg-dark d-flex justify-content-center align-items-center" style="--bs-dark-rgb:149,117,205;">
    <div class="">
    <div class="card border-0 w-100 bg-transparent" [ngClass]="{'btn-invalid':!isValidLogin&& validationsubmited,'btn-valid':isValidLogin&& validationsubmited}" >       
        <div class="card-body">
            <div class="position-relative py-3">
                <div class="position-absolute d-flex justify-content-center align-items-center w-100 h-100 bg-white z-3 bg-opacity-50" *ngIf="validationsubmited">
                    
                    <div class="v-spinner" >
                        <div class="v-sync v-sync1 spinnerStyle spinnerDelay1"  >
                        </div><div class="v-sync v-sync2 spinnerStyle spinnerDelay2"  >
                        </div><div class="v-sync v-sync3 spinnerStyle spinnerDelay3"  >
                        </div>
                      </div>
                </div>
            <div class="text-center my-3">
                <button class="btn text-dark bg-white"   (click)="loginPopup()"><img src="/assets/img/Microsoft_logo.png" style="max-width: 100%;" width="25" height="25" class="img-fluid" alt="image"> <span class="ps-2 text-secondary fs-14" style="--bs-secondary-rgb:33,37,41;">Sign in with Microsoft</span></button> 
            </div>
            <div class="text-center d-flex align-items-center justify-content-center">
                <asl-google-signin-button type='standard' size='large'   shape="rectangular" text="signin_with" class="mx-auto"></asl-google-signin-button>
                
            </div>
            <div class="d-none"> <div class="text-center my-3">Or</div>
            <form  [formGroup]="loginForm" (ngSubmit)="onSubmit($event)"> 
			<div class="mb-4 input-group rounded-0">
			<span class="input-group-text bg-transparent border-0"><i class="bi bi-person-fill"></i></span>
                <div class="form-floating bg-light">
                    <input type="email" class="form-control bg-light rounded" id="floatingInput" placeholder="..." formControlName="username"  [ngClass]="{ 'is-invalid': submitted && f['username'].errors }" required>
                    <label for="floatingInput">Your email</label>
                  </div>
				  </div>
				  <div class="mb-4 input-group rounded-0">
				  <span class="input-group-text bg-transparent border-0"><i class="bi bi-lock"></i></span>
                  <div class="form-floating ">
                    <input type="password" class="form-control bg-light rounded" id="floatingPassword" placeholder="Password" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" required>
                    <label for="floatingPassword">Your password</label>
                  </div>
				  </div>
				  <div>
				 
				  </div>
                <div class="form-group justify-content-center d-flex mb-3">
				<div class="w-50">
            <button type="submit" class="btn btn-info text-white fw-bold rounded-pill w-100" [disabled]="loading">Log In</button>
			</div>
                </div>
               
            </form>
            
    </div>
    <div *ngIf="submitted && !isValidLogin"  class="text-center lh-1 text-danger mt-3 fs-14">User account doesn't exist.</div>
    </div>
    </div>
       
    </div>
    <div class="px-3 fs-14 text-center" style="--bs-secondary-rgb:33,37,41;">
		<p >By continuing, you are indicating that you have read and agree to the <a href="#" class="text-secondary">Terms of Use</a> and <a href="#" class="text-secondary">Privacy Policy</a>. </p>
		</div>
<div class="mt-3 px-3 text-center fs-14" style="--bs-secondary-rgb:33,37,41;"><p>&copy; {{currentyear}} ALICORN INC.<br>Need help? <a href="#" class="text-secondary">support@alicorn.co</a></p></div>
    </div>
</div>

    </div>


</div>