import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { AuthGuard } from './helper/auth.guard';
import { LogoutComponent } from './auth/logout/logout.component';

const routes: Routes = [{
  path:"",
  canActivate: [AuthGuard],
  loadChildren:()=>import('./pages/landing.module').then(m=>m.LandingModule)
    },{
  path:"login",
  component:LoginComponent

},{
  path:"logout",
  component:LogoutComponent

}];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
