import { NgModule, APP_INITIALIZER,ElementRef, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { AppConfigService } from "./services/app-config.service";

import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { SocialAuthServiceConfig } from "@abacritt/angularx-social-login";
import { GoogleLoginProvider,MicrosoftLoginProvider } from "@abacritt/angularx-social-login";
import {  GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { LogoutComponent } from './auth/logout/logout.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
 

export function initializeApp(appConfigService: AppConfigService) {
  return (): Promise<any> => {
    return appConfigService.load();
  };
}


  

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SocialLoginModule,
    PowerBIEmbedModule,
    HttpClientModule,ReactiveFormsModule,FormsModule,GoogleSigninButtonModule
  ],
  providers: [ 
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfigService],
      multi: true,
    }, {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '194707782148-cppclq0hgbsjtphudqif168q876kiraf.apps.googleusercontent.com', 
            )
          },
          {
            id: MicrosoftLoginProvider.PROVIDER_ID,
            provider: new MicrosoftLoginProvider('fad25e77-11bd-4192-bb5d-a106e4902032', {
          redirect_uri: 'https://birdie.alicorn.co/',
              logout_redirect_uri: 'https://birdie.alicorn.co//login'
//fad25e77-11bd-4192-bb5d-a106e4902032
//1087cdb1-0c33-4e7f-9c79-24d674d0165f
           /*redirect_uri: 'https://localhost:4200',
              logout_redirect_uri: 'https://localhost:4200/login'*/
            })
          }
        ]
      } as SocialAuthServiceConfig
    }],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
 